import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SplitButton from 'app/components/buttons/split-button.component'
import { FormItems, IndividualDetails } from 'api/models'
import { IndividualDetailsCard } from 'modules/clients/components/individual-details.component'
import { TabPanel } from 'app/components/tab-panel.component'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDateWithTime } from 'app/utils/format'
import { IndividualEnterprisesComponent } from 'modules/clients/components/individual-enterprises.component'
import { IndividualConsumptions } from 'modules/clients/components/individual-consumptions.component'
import { IndividualDevices } from 'modules/clients/components/individual-devices.component'
import { PaperGreyComponent } from 'app/components/papers/paper-grey.component'
import { useTheme } from '@mui/system'
import { useApp } from 'app/providers/app.provider'
import { AddEventDialog } from 'modules/events/views/add-event-dialog.component'
import { DialogRef } from 'app/components/dialog/dialog.component'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import dayjs from 'dayjs'
import { IndividualAccess } from 'modules/clients/components/individual-access.component'
import { ModulesEnum } from 'app/constants/modules'
import { useSidebar } from 'app/providers/sidebar.provider'
import { useFeedback } from 'app/providers/feedback.provider'
import { IndividualAccounting } from '../components/individual-accounting.component'

export const IndividualView = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { setParams } = useSidebar()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [individual, setIndividual] = useState<IndividualDetails>({} as IndividualDetails)
  const { getIndividual, getFormItems, activateAccount, resetPassword } = useFetcher()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [options, setOptions] = useState({} as FormItems)
  const navigate = useNavigate()
  const dialogRef = useRef<DialogRef>(null)
  const { getModule, getRight } = useApp()
  const [hasVisorAccess, setHasVisorAccess] = useState(false)
  const { handleMutation } = useFeedback()

  const [commonOptions] = useState<Map<string, string>>(
    new Map<string, string>([
      ['individual_roles', 'role'],
      ['centers', 'center'],
      ['event_types', 'type'],
      ['event_status', 'status'],
      ['prescriber_types', 'type'],
      ['opportunity_ground_refusals', 'opp_ground_refusal'],
      ['ground_refusal', 'ground_refusal'],
      ['opportunities', 'opportunity']
    ])
  )

  const handleTabChange = async (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const fetch = useCallback(async () => {
    setIsLoading(true)
    getIndividual
      .mutateAsync(String(id))
      .then((data) => {
        setIndividual(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [id, getIndividual])

  const initOptions = useCallback(
    async (commonOptions: Map<string, string>) => {
      await getFormItems
        .mutateAsync(Array.from(commonOptions.keys() as any))
        .then((optionsData) => {
          setOptions(optionsData as FormItems)
        })
    },
    [getFormItems, setOptions]
  )

  const onActivateAccount = useCallback(async () => {
    if (individual.id && !individual.isAccountActive) {
      await handleMutation({
        confirm: {
          content: t('confirm_activate_account')
        },
        mutation: activateAccount,
        data: { id: String(individual.id) },
        onEnd: () => navigate(0)
      })
    }
  }, [individual])

  const onResetPassword = useCallback(async () => {
    if (individual.id && individual.isAccountActive) {
      await handleMutation({
        confirm: {
          content: t('confirm_reset_password')
        },
        mutation: resetPassword,
        data: { id: String(individual.id) },
        onEnd: () => navigate(0)
      })
    }
  }, [individual])

  useEffect(() => {
    setParams({ id: String(id), type: 'clients', data: { client: String(id) } })
    initOptions(commonOptions).then(() => fetch())
  }, [])

  useEffect(() => {
    const _module = getModule(ModulesEnum.ACCESS)
    const canView = getRight('visor_access', 'isDisplay')
    if (_module !== undefined) {
      setHasVisorAccess(canView)
    } else {
      setHasVisorAccess(false)
    }
  }, [getModule, getRight])

  const theme = useTheme()
  const { Accent } = useApp()

  return isLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      {
        <AddEventDialog
          onSuccess={fetch}
          defaultValues={{
            type: String(options?.event_types?.default[0]),
            status: String(options?.event_status?.default[0]),
            staff: 0,
            ownerName: individual.labelledName,
            center: String(individual.mainCenter),
            begin: dayjs()
          }}
          options={options}
          dialogRef={dialogRef}
        />
      }
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h1">
              {t('user')} | {individual.labelledName}
            </Typography>
            <SplitButton
              items={[
                {
                  label: t('activate_account'),
                  key: 'activate_account',
                  action: () => onActivateAccount(),
                  isHide: individual.isAccountActive
                },
                {
                  label: t('reset_password'),
                  key: 'reset_password',
                  action: () => onResetPassword(),
                  isHide: !individual.isAccountActive
                },
                {
                  label: t('add_consumption'),
                  key: 'add_consumption',
                  action: () => {
                    navigate({
                      pathname: `/individuals/${id}/consumptions/add`,
                      search: `?center=${individual.mainCenter}`
                    })
                  }
                },
                {
                  label: t('add_event'),
                  key: 'add_event',
                  action: () => dialogRef.current?.open()
                }
              ]}
            />
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2">{individual.reference}</Typography>

      <PaperGreyComponent>
        <Grid container>
          <Grid item xs={6} sm={4} md={4} sx={{ padding: theme.spacing(4) }}>
            <Typography variant="subtitle2">
              <Accent>{t('last_connexion_at')} : </Accent>{' '}
              {formatDateWithTime(individual.lastConnection ? individual.lastConnection : '')}
            </Typography>
          </Grid>
          <Grid item sm={4} md={4} sx={{ padding: theme.spacing(4) }}>
            <Typography variant="subtitle2">
              <Accent>{t('created_at')} : </Accent> {formatDateWithTime(individual.createdAt)}
            </Typography>
          </Grid>
          <Grid item sm={4} md={4} sx={{ padding: theme.spacing(4) }}>
            <Typography variant="subtitle2">
              <Accent>{t('updated_at')} : </Accent>{' '}
              {formatDateWithTime(individual.updatedAt ? individual.updatedAt : '')}
            </Typography>
          </Grid>
        </Grid>
      </PaperGreyComponent>

      <PaperGreyComponent>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={t('informations')} />
          <Tab label={t('enterprise')} />
          <Tab label={t('consumptions')} />
          {(getRight('accounting_client', 'isShow') ||
            getRight('accounting_contact_clientcenter', 'isShow')) && (
            <Tab label={t('accounting_informations')} />
          )}
          <Tab label={t('devices')} />
          {hasVisorAccess && <Tab label={t('access')} />}
        </Tabs>
      </PaperGreyComponent>

      <TabPanel value={currentTab} index={0}>
        <IndividualDetailsCard individual={individual} options={options} />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <IndividualEnterprisesComponent individual={individual} options={options} />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        <IndividualConsumptions individual={individual} />
      </TabPanel>
      {(getRight('accounting_client', 'isShow') ||
        getRight('accounting_contact_clientcenter', 'isShow')) && (
        <TabPanel value={currentTab} index={3}>
          <IndividualAccounting individual={individual} />
        </TabPanel>
      )}

      <TabPanel value={currentTab} index={4}>
        <IndividualDevices individual={individual} />
      </TabPanel>

      <TabPanel value={currentTab} index={5}>
        <IndividualAccess individual={individual} />
      </TabPanel>
    </Container>
  )
}
